<template>
  <swiper
    :class="['yl-channel', isMobile ? 'mob-wrap' : 'pc-wrap']"
    :options="channelOption"
  >
    <swiper-slide v-for="(item, i) in dataList" :key="i">
      <div @click="toChannel(item.id)">
        <div :class="isMobile ? 'mob' : 'pc'">
          <img :src="item.channelLogo" alt="" />
        </div>
        <p>{{ item.channelName }}</p>
      </div>
    </swiper-slide>
  </swiper>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import { channelList } from "../api/home";
export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    // dataList: {
    //   type: Array,
    //   default: function () {
    //     return [
    //       {
    //         channellogo:
    //           "http://172.145.0.77:8080/upload/776073c4-dd87-49d2-bda7-873b28aa9135.jpg",
    //         channelname: "测试",
    //         id: 5,
    //       },
    //       {
    //         channellogo:
    //           "http://172.145.0.77:8080/upload/2b1db408-93ad-4c10-8124-a59669461326.png",
    //         channelname: "其他研究",
    //         id: 2,
    //       },
    //       {
    //         channellogo:
    //           "http://172.145.0.77:8080/upload/776073c4-dd87-49d2-bda7-873b28aa9135.jpg",
    //         channelname: "测试",
    //         id: 5,
    //       },
    //       {
    //         channellogo:
    //           "http://172.145.0.77:8080/upload/2b1db408-93ad-4c10-8124-a59669461326.png",
    //         channelname: "测试",
    //         id: 5,
    //       },
    //       {
    //         channellogo:
    //           "http://172.145.0.77:8080/upload/776073c4-dd87-49d2-bda7-873b28aa9135.jpg",
    //         channelname: "测试",
    //         id: 5,
    //       },
    //       {
    //         channellogo:
    //           "http://172.145.0.77:8080/upload/2b1db408-93ad-4c10-8124-a59669461326.png",
    //         channelname: "测试",
    //         id: 5,
    //       },
    //       {
    //         channellogo:
    //           "http://172.145.0.77:8080/upload/776073c4-dd87-49d2-bda7-873b28aa9135.jpg",
    //         channelname: "测试",
    //         id: 5,
    //       },
    //     ];
    //   },
    // },
  },
  data() {
    return {
      channelOption: {
        slidesPerView: 5,
        freeMode: true,
      },
      isMobile: this.$isMobile(),
      dataList: [],
    };
  },
  created() {
    this.getChannelList();
  },
  methods: {
    toChannel(id) {
      this.$router.push({ path: "/channel/" + id });
    },
    getChannelList() {
      channelList().then((res) => {
        this.dataList = res.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
img {
  width: 100%;
  display: block;
}
p {
  margin: 0;
}
.yl-channel {
  background: #fff;
  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20%;
    .mob,
    .pc {
      margin: 0 auto;
      border-radius: 50%;
      overflow: hidden;
    }
    .mob {
      width: 0.45rem;
      height: 0.45rem;
    }
    .pc {
      width: 70px;
      height: 70px;
    }
    p {
      text-align: center;
    }
  }
}
.mob-wrap {
  height: 1.1rem;
  font-size: 0.12rem;
  border-radius: 0.08rem;
  p {
    padding-top: 0.1rem;
  }
}
.pc-wrap {
  height: 160px;
  font-size: 16px;
  border-radius: 8px;
  p {
    padding-top: 10px;
  }
}
</style>
