<template>
  <div class="page-view">
    <mob-header></mob-header>
    <div class="wrap">
      <yl-swiper :dataList="slideDataList"></yl-swiper>
      <yl-channel></yl-channel>
      <div class="title">
        <h4>最新活动</h4>
        <p @click="toMore()">
          <span>更多</span>
          <i><img src="../../assets/right.png" alt="" /></i>
        </p>
      </div>
      <mob-video
        v-for="(item, i) in newDataList"
        :key="i"
        :data="item"
        @refDataList="getNewList()"
      ></mob-video>
      <div class="bei">
      <a href="https://beian.miit.gov.cn/" target="_blank">沪ICP备13042431号-4</a>
    </div>
    </div>
    
    <mob-footer-bar></mob-footer-bar>
    <!-- <qrcode-popup></qrcode-popup> -->
  </div>
</template>

<script>
import MobHeader from "../../components/mob-header.vue";
import YlSwiper from "../../components/yl-swiper.vue";
import YlChannel from "../../components/yl-channel.vue";
import MobVideo from "../../components/mob-video.vue";
import MobFooterBar from "../../components/mob-footer-bar.vue";
import { slideList, newList } from "../../api/home";
import wechat from "../../utils/wxShare";
import { wechatConfig } from "../../api/user";
// import QrcodePopup from "../../components/qrcode-popup.vue";
export default {
  components: {
    MobHeader,
    YlSwiper,
    YlChannel,
    MobVideo,
    MobFooterBar,
    // QrcodePopup,
  },
  data() {
    return {
      slideDataList: [],
      newDataList: [],
    };
  },
  created() {
    this.getSlideList();
    this.getNewList();
    this.getWechatConfig();
  },
  methods: {
    getWechatConfig() {
      wechatConfig({
        url: location.href,
      }).then((res) => {
        if (res.code == 0) {
          this.wxConfigData = res.data;
          wechat.share(
            this.wxConfigData,
            "因美纳在线讲堂",
            "因美纳在线讲堂",
            this.$store.state.url,
            location.href
          );
        }
      });
    },
    toMore() {
      this.$router.push({ path: "/home/more" });
    },
    // 轮播图数据
    getSlideList() {
      slideList().then((res) => {
        if (res.code == 0) {
          this.slideDataList = res.data;
        }
      });
    },
    // 最新活动数据
    getNewList() {
      // userInfo().then((res) => {
      //   if (res.code == 0) {
      //     this.userId = res.data.id;
      //   }
      newList().then((res) => {
        if (res.code == 0) {
          this.newDataList = res.data;
        }
      });
      // });
    },
  },
};
</script>

<style lang="scss" scoped>
.page-view {
  background: url("../../assets/h5bg.png") 0 0 no-repeat;
  background-size: 100% auto;
  .wrap {
    padding: 0 0.1rem 0.9rem;
    .yl-swiper {
      height: 1.68rem;
      border-radius: 0.08rem;
      border: none;
    }
    .yl-channel {
      margin-top: 0.12rem;
    }
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 0.35rem;
      margin-top: 0.1rem;
      h4 {
        margin: 0;
        font-size: 0.16rem;
      }
      p {
        cursor: pointer;
        display: flex;
        align-items: center;
        font-size: 0.14rem;
        i {
          display: inline-block;
          width: 0.1rem;
          height: 0.1rem;
          margin-left: 0.1rem;
          img {
            width: 100%;
          }
        }
      }
    }
    .bei {
      text-align: center;
      a {
        text-decoration: none;
        font-size: 0.12rem;
        color: #666;
      }
    }
  }
}
</style>
