<template>
  <div class="page-view">
    <pc-header></pc-header>
    <div class="main">
      <yl-swiper :dataList="slideDataList"></yl-swiper>
      <yl-channel></yl-channel>
      <div>
        <div class="title">
          <h4>最新活动</h4>
          <p @click="toMore()">
            <span>更多</span>
            <i><img src="../../assets/right.png" alt="" /></i>
          </p>
        </div>
        <div class="video-list">
          <pc-video
            v-for="(item, i) in newDataList"
            :key="i"
            :data="item"
            @refDataList="getNewList()"
          ></pc-video>
        </div>
      </div>
    </div>
    <pc-footer></pc-footer>
  </div>
</template>

<script>
import PcHeader from "../../components/pc-header.vue";
import PcFooter from "../../components/pc-footer.vue";
import YlSwiper from "../../components/yl-swiper.vue";
import YlChannel from "../../components/yl-channel.vue";
import PcVideo from "../../components/pc-video.vue";
import { slideList, newList } from "../../api/home";
export default {
  components: {
    PcHeader,
    PcFooter,
    YlSwiper,
    YlChannel,
    PcVideo,
  },
  data() {
    return {
      slideDataList: [],
      newDataList: [],
    };
  },
  created() {
    this.getSlideList();
    this.getNewList();
  },
  methods: {
    toMore() {
      this.$router.push({ path: "/home/more" });
    },
    // 轮播图数据
    getSlideList() {
      slideList().then((res) => {
        this.slideDataList = res.data;
      });
    },
    // 最新活动数据
    getNewList() {
      newList({
        id: "1",
      }).then((res) => {
        this.newDataList = res.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.page-view {
  background: url("../../assets/pcbg.png") 0 0 no-repeat;
  background-size: 100% auto;
  .main {
    width: 1200px;
    margin: 0 auto;
    .yl-swiper {
      height: 406px;
      border-radius: 8px;
      margin-top: 36px;
    }
    .yl-channel {
      margin-top: 27px;
    }
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 70px;
      margin-top: 14px;
      h4 {
        margin: 0;
        font-size: 24px;
      }
      p {
        cursor: pointer;
        display: flex;
        align-items: center;
        font-size: 16px;
        i {
          display: inline-block;
          width: 16px;
          height: 16px;
          margin-left: 10px;
          img {
            width: 100%;
          }
        }
      }
    }
    .video-list {
      display: flex;
      flex-wrap: wrap;
      min-height: 200px;
      // justify-content: space-between;
      .pc-video {
        margin-bottom: 34px;
      }
      .pc-video:nth-child(3n-1) {
        margin: 0 15px;
      }
    }
  }
}
</style>
