<template>
  <div class="page-view">
    <home-mob v-if="isMobile"></home-mob>
    <home-pc v-else></home-pc>
  </div>
</template>

<script>
import HomePc from "./home-pc.vue";
import HomeMob from "./home-mob.vue";
import { setToken, getToken } from "../../utils/auth";
export default {
  components: {
    HomePc,
    HomeMob,
  },
  metaInfo: {
    title: "因美纳讲堂 | 首页",
  },
  data() {
    return {
      isMobile: this.$isMobile(),
    };
  },
  created() {
    if (getToken()) {
      setToken(getToken());
    }

    // console.log(this.$router);
    // console.log(this.$store.state.url);
  },
};
</script>
